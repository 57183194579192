import request from "@/utils/request";
const appName = "huanxinxiang-taiyue";

// 支付宝购买会员-无需验证码-无需Token
export function alipayPayAPI(data) {
  return request({
    url: "/huanxinxiang-service/v1/alipay-pay/",
    method: "post",
    headers: {
      "App-Name": appName,
    },
    data,
  });
}
